<template>
  <div class="table_actions">
    <call-tooltip
      placement="top"
      content="Info">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataViewIcon"
        @click="clickAction('view')" />
    </call-tooltip>
    <call-tooltip
      v-if="computedIsAdmin"
      placement="top"
      content="Edit">
      <call-button
        outlet
        type="warning"
        round
        not-border
        :icon="dataReadingIcon"
        @click="clickAction('update')" />
    </call-tooltip>
    <call-tooltip
      v-if="((computedIsAdmin || computedIsModerator) && computedCurrentUser.id !== userId)"
      placement="top"
      :content="computedActive ? 'Deactivate' : 'Active'">
      <call-button
        outlet
        :type="computedActive ? 'danger' : 'success'"
        round
        not-border
        :icon="dataIconPower"
        @click="clickAction('active')" />
    </call-tooltip>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faEye, faLock, faLockOpen, faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { mapGetters } from 'vuex';
import helper from '../../service/helper';

export default {
  name: 'CallTableTeamsOperatorActions',
  components: { CallButton },
  props: {
    teams: {
      type: Array,
      default: () => ({})
    },
    userId: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      dataViewIcon: faEye,
      dataReadingIcon: faPen,
      dataLockIcon: faLock,
      dataLockOpenIcon: faLockOpen,
      dataIconPower: faPowerOff
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsModerator: 'auth/isModerator',
      computedCurrentUser: 'auth/currentUser'
    }),
    computedActive () {
      // eslint-disable-next-line camelcase
      return helper.getOneArray(this.teams)?.is_active_in_team;
    }
  },
  methods: {
    clickAction (action) {
      this.$emit('on-action', action);
    }
  }
};
</script>

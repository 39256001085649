<template>
  <div>
    <call-card-user-detail
      :obj-card="dataUserObj"
      @on-update="dataShowUpdateUser = true;" />
    <call-table-operators
      v-if="computedIsTable"
      :team-id="computedTeamId" />
    <call-modal-update-user
      v-model="dataShowUpdateUser"
      :user-id="dataUserObj.id"
      @on-update="() => $_ajaxUsers()" />
  </div>
</template>

<script>
import { USERS_GET } from '../../../store/types/actions.types';
import CallCardUserDetail from '../../../components/common/cards/userDetailCard';
import CallTableOperators from '../../../components/desktop/CallTableOperators';
import helper from '../../../service/helper';
import CallModalUpdateUser from '../../../components/common/modals/CallUpdateUser';
import { ID_ROLE_TEAM_LEADER } from '../../../service/consts';

export default {
  name: 'PageUsersDetail',
  components: { CallModalUpdateUser, CallTableOperators, CallCardUserDetail },
  data () {
    return {
      dataUserObj: {},
      dataShowUpdateUser: false
    };
  },
  computed: {
    computedTeamId () {
      return helper.getOneArray(this.dataUserObj.teams)?.id;
    },
    computedIsTable () {
      return this.computedTeamId && this.dataUserObj?.role === ID_ROLE_TEAM_LEADER;
    }
  },
  watch: {
    '$route.query' () {
      if (this.$route.name === 'UserDetail') {
        setTimeout(_ => {
          this.$router.go(0);
        }, 150);
      }
    }
  },
  created () {
    this.$_ajaxUsers();
  },
  methods: {
    async $_ajaxUsers () {
      try {
        const { user } = await this.$store.dispatch(`user/${USERS_GET}`, {
          params: {
            id: this.$route.params?.id
          }
        });
        this.dataUserObj = user;
      } catch (e) {

      }
    }
  }
};
</script>

import {
  faClipboardList,
  faEnvelope,
  faFire,
  faLock,
  faLockOpen,
  faPen,
  faPhone,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import CallButton from '../CallButton';
import { USERS_BLOCKED } from '../../../store/types/actions.types';
import { BBadge } from 'bootstrap-vue';
import { ID_ROLE_ADMIN, ID_ROLE_OPERATOR, ID_ROLE_TEAM_LEADER } from '../../../service/consts';
import { mapGetters } from 'vuex';
import helper from '../../../service/helper';

export default {
  name: 'CallCardUserDetail',
  components: {
    CallButton,
    BBadge
  },
  props: {
    objCard: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      dataIconLockClose: faLock,
      dataIconLockOpen: faLockOpen,
      dataIconReading: faPen,
      dataIconEmail: faEnvelope,
      dataIconTeams: faUsers,
      dataIconPhone: faPhone,
      dataIconFire: faFire,
      dataIconClipboardCheck: faClipboardList

    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedCurrentUser: 'auth/currentUser'
    }),
    computedCallMode () {
      switch (helper.getOneArray(this.objCard?.teams)?.call_mode) {
        case 'asterisk':
          return 'Asterisk';
        case 'custom':
          return 'Custom';
        case 'manual':
          return 'Manual';
      }
      return helper.getOneArray(this.objCard?.teams)?.call_mode;
    }
  },
  methods: {
    async clickBlocked () {
      try {
        if (!helper.isEmpty(this.objCard?.teams) && this.objCard?.role === ID_ROLE_TEAM_LEADER) {
          this.$Notify({
            title: 'Oops',
            message: 'This user is a team leader. You should demote him firstly.',
            type: 'warning'
          });
          return;
        }
        await this.$store.dispatch(`user/${USERS_BLOCKED}`, {
          params: {
            userId: this.objCard.id
          }
        });
        this.objCard.is_blocked = !this.objCard.is_blocked;
        this.$Notify({
          title: 'User',
          message: `successfully  ${this.objCard.is_blocked ? 'locked' : 'unlocked'}`,
          type: 'success'
        });
      } catch (e) {
        throw Error('Ошибка блокировки');
      }
    }
  },
  render (h) {
    return (
      <div class="card-wrapper">
        <div class="card-project">
          <div class="card-project__header card-project__header--between">
            <div class="card-project__header-info">
              <p>ID: {this.objCard?.id}</p>
              <p>|</p>
              <p>
                {/* eslint-disable-next-line camelcase */}
                {this.$options.filters.dateFilter(this.objCard?.created_at)}
              </p>
            </div>
            <div class="card-project__btn-wrapper">
              {
                this.computedCurrentUser.id !== this.objCard.id && this.computedIsAdmin
                  ? <call-button type={!this.objCard.is_blocked ? 'danger' : 'success'} plain
                    icon={!this.objCard.is_blocked ? this.dataIconLockClose : this.dataIconLockOpen}
                    onClick={this.clickBlocked}>
                    &nbsp;{!this.objCard.is_blocked ? 'Block' : 'Unblock'}
                  </call-button> : null
              }
              {
                this.computedIsAdmin
                  ? <call-button type="success" plain icon={this.dataIconReading} onClick={() => this.$emit('on-update')}>
                    &nbsp;Edit
                  </call-button> : null
              }
            </div>
          </div>
          <div class="card-project__body">
            <p class="card-project__title">
              {this.objCard?.name}
              {
                ~[ID_ROLE_TEAM_LEADER, ID_ROLE_ADMIN].indexOf(this.objCard?.role)
                  ? <b-badge variant={ID_ROLE_TEAM_LEADER === this.objCard?.role ? 'success' : 'primary'}>
                    {ID_ROLE_TEAM_LEADER === this.objCard?.role ? 'Team Leader' : 'Administration'}
                  </b-badge> : null
              }
            </p>
            <p class="card-project__info">
              <font-awesome-icon icon={this.dataIconEmail}/>
              &nbsp;{this.objCard?.email}
            </p>
            {
              this.objCard?.role === ID_ROLE_ADMIN ? null
                : <p class="card-project__info">
                  <font-awesome-icon icon={this.dataIconTeams}/>
                  &nbsp;{this.$options.filters.joinName(this.objCard?.teams)}
                </p>
            }
            {
              this.objCard?.role === ID_ROLE_OPERATOR && this.computedCallMode &&
                <p class="card-project__info">
                  <font-awesome-icon icon={this.dataIconPhone}/>
                  &nbsp;{this.computedCallMode}
                </p>
            }
          </div>
        </div>
        {this.objCard?.role === ID_ROLE_OPERATOR &&
        [
          <div class="card-project__info-wrapper--vertical">
            <div class="card-project__info-block card-project">
              <div class="success icon">
                <font-awesome-icon
                  icon={this.dataIconPhone}/>
              </div>
              <div class="card-project__info-block__line">
                <p>Calls</p>
                <p>{(this.objCard?.outcoming_cancelled_calls_count || 0) + (this.objCard?.outcoming_finished_calls_count || 0)}</p>
              </div>
              <div class="card-project__info-block__line success">
                <span>Finished</span>
                <span>{this.objCard?.outcoming_finished_calls_count || 0}</span>
              </div>
              <div class="card-project__info-block__line danger">
                <span>Cancelled</span>
                <span>{this.objCard?.outcoming_cancelled_calls_count || 0}</span>
              </div>
              <div class="card-project__info-block__line">
                <span>Incoming</span>
                <span>{this.objCard?.incoming_calls_count || 0}</span>
              </div>
              <div class="card-project__info-block__line">
                <span>Spoken</span>
                <span>{this.$options.filters.dateReport(this.objCard?.calls_duration) || 0}</span>
              </div>
              <div class="card-project__info-block__footer">
                <div class="card-project__info-block__line">
                  <span>SMS</span>
                  <span>{this.objCard?.sms_count || 0}</span>
                </div>
                <div class="card-project__info-block__line">
                  <span>Gifts</span>
                  <span>{this.objCard?.bonuses_count || 0}</span>
                </div>
              </div>
            </div>
          </div>,

          <div class="card-project__info-wrapper--horinzontal">
            <div class="card-project__info-block card-project">
              <div class="card-project__info-block__part">
                <div class="warning icon">
                  <font-awesome-icon
                    icon={this.dataIconFire}/>
                </div>
                <div class="card-project__info-block__line">
                  <p>Open Tasks</p>
                  <p>{this.objCard?.open_tasks_count || 0}</p>
                </div>
                <div class="card-project__info-block__footer">
                  <div class="card-project__info-block__line">
                    <span>Avg. per Day</span>
                    <span>{this.objCard?.avg_processed_tasks || 0}</span>
                  </div>
                </div>
              </div>
              <div class="card-project__info-block__part">
                <div class="success icon">
                  <font-awesome-icon
                    icon={this.dataIconClipboardCheck}/>
                </div>
                <div class="card-project__info-block__line">
                  <p>Closed Tasks</p>
                  <p>{(this.objCard?.auto_closed_tasks_count || 0) + (this.objCard?.manually_closed_tasks_count || 0)}</p>
                </div>
                <div class="card-project__info-block__line success">
                  <span>Auto Assigned</span>
                  <span>{this.objCard?.auto_closed_tasks_count || 0}</span>
                </div>
                <div class="card-project__info-block__line success">
                  <span>Auto Assigned VIP</span>
                  <span>{this.objCard?.auto_closed_vip_tasks_count || 0}</span>
                </div>
                <div class="card-project__info-block__line danger">
                  <span>Manually</span>
                  <span>{this.objCard?.manually_closed_tasks_count || 0}</span>
                </div>
              </div>
            </div>
          </div>
        ]
        }
      </div>
    );
  }
};

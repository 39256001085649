<template>
  <div>
    <div class="table__title filter-wrapper">
      <h2>Operators ({{ dataUserCountAll }})</h2>
      <call-pagination
        :obj-pagination="dataObjPagination"
        @on-pagination="$_ajax" />
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        no-local-sorting
        :fields="computedUserFields"
        :items="dataUserList"
        @sort-changed="onSortTabel"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-hovered="onHover"
        @row-unhovered="onUnHover">
        <template #cell(name)="data">
          {{ data.item.name }}
          <b-badge
            v-if="data.item.role === 'admin'"
            variant="primary">
            Admin
          </b-badge>
          <b-badge
            v-else-if="data.item.role === 'lead'"
            variant="success">
            Team Leader
          </b-badge>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateFilter }}
        </template>
        <template #cell(teams)="data">
          {{ data.item.teams | joinName }}
        </template>
        <template #cell(isBlocked)="data">
          <font-awesome-icon
            v-if="data.item.is_blocked"
            class="tabel-icon--red"
            :icon="dataIconLock" />
          <font-awesome-icon
            v-else
            class="tabel-icon--green"
            :icon="dataIconCheck" />
        </template>
        <template #cell(isActive)="data">
          <font-awesome-icon
            :class="[templateActiveUser(data.item) ? 'tabel-icon--red' : 'tabel-icon--green']"
            :icon="dataIconPower" />
        </template>
        <template #cell(call_mode)="data">
          {{ templateCallMode(data.item.teams) }}
        </template>
        <template #cell(actions)="data">
          <call-table-teams-operator-actions
            v-if="type === 'team'"
            v-show="data.item.actions"
            :teams="data.item.teams"
            @on-action="(value) => onActions(data.item, value)" />
          <call-table-actions
            v-else
            v-show="data.item.actions"
            :block="data.item.is_blocked"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
      </b-table>
    </div>
    <call-modal-update-user
      v-model="dataShowUpdateUser"
      :user-id="dataIdUpdateUser"
      @on-update="() => $_ajax()" />
  </div>
</template>

<script>
import userTable from '../../mixins/table/userTable';
import { TEAM_ACTIVE_USER_OR_DEACTIVATE, USERS_BLOCKED, USERS_GET_ALL } from '../../store/types/actions.types';
import { faCheck, faChevronLeft, faChevronRight, faLock, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { BBadge, BTable } from 'bootstrap-vue';
import CallTableActions from './CallTableActions';
import CallPagination from './CallPagination';
import { ID_ROLE_OPERATOR } from '../../service/consts';
import CallModalUpdateUser from '../common/modals/CallUpdateUser';
import helper from '../../service/helper';
import CallTableTeamsOperatorActions from './CallTableTeamsOperatorActions';

export default {
  name: 'CallTableOperators',
  components: {
    CallTableTeamsOperatorActions,
    CallModalUpdateUser,
    CallPagination,
    CallTableActions,
    BBadge,
    BTable
  },
  mixins: [userTable],
  props: {
    teamId: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      dataIconCheck: faCheck,
      dataIconLock: faLock,
      dataIconPower: faPowerOff,
      dataRowLeft: faChevronLeft,
      dataRowRight: faChevronRight,
      dataObjPagination: {},
      dataUserCountAll: null,
      dataUserList: [],
      dataShowUpdateUser: false,
      dataIdUpdateUser: null
    };
  },
  computed: {
    computedUserFields () {
      if (this.type === 'team') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dataMixinUserFields[0].key = 'isActive';
        return this.dataMixinUserFields.filter(item => item.key !== 'teams');
      }
      return this.dataMixinUserFields.filter(item => item.key !== 'call_mode');
    }
  },
  mounted () {
    this.$_ajax();
  },
  created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = 10;
      return async ({ desc = this.dataDesc, orderBy = this.dataOrderBy, limit = null, page = null } = options) => {
        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        const { payload: { list = [] }, misc: { paging = {} } } = await this.$store.dispatch(`user/${USERS_GET_ALL}`, {
          params: {
            role: ID_ROLE_OPERATOR,
            team_ids: [this.teamId],
            desc,
            order_by: orderBy,
            limit: _limit,
            page: _page
          }
        });
        this.dataObjPagination = paging;
        this.dataUserCountAll = paging?.count || 0;
        this.dataUserList = list.map((item) => {
          item.actions = false;
          return item;
        });
      };
    })();
  },
  methods: {
    onSortTabel ({ sortBy, sortDesc }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    templateActiveUser ({ teams = [] }) {
      // eslint-disable-next-line camelcase
      return !(helper.getOneArray(teams)?.is_active_in_team);
    },
    templateCallMode (teams = []) {
      switch (teams.find(item => item.id === this.teamId)?.call_mode) {
        case 'asterisk':
          return 'Asterisk';
        case 'custom':
          return 'Custom';
        case 'manual':
          return 'Manual';
      }
      return teams.find(item => item.id === this.teamId)?.call_mode;
    },
    async  onActions ({ id: userId, is_blocked: isBlocked, name = '', teams = [] }, action) {
      switch (action) {
        case 'update':
          this.dataIdUpdateUser = userId;
          this.dataShowUpdateUser = true;
          break;
        case 'view':
          // eslint-disable-next-line no-case-declarations
          const { name: nameRouter = '' } = this.$route;
          if (nameRouter === 'UserDetail') {
            this.$router.push({
              name: 'UserDetail',
              params: {
                id: userId
              },
              query: {
                userName: name
              }
            }, () => {
              this.$router.go(0);
            });
          } else {
            this.$router.push({
              name: 'UserDetail',
              params: {
                id: userId
              },
              query: {
                userName: name
              }
            });
          }
          break;
        case 'blocked':
          try {
            await this.$store.dispatch(`user/${USERS_BLOCKED}`, {
              params: {
                userId
              }
            });
            await this.$_ajax();
            this.$Notify({
              title: 'User',
              message: `Successfully ${!isBlocked ? 'locked' : 'unlocked'}`,
              type: 'success'
            });
          } catch (e) {
            throw Error('Ошибка блокировки');
          }
          break;
        case 'active':
          try {
            if (helper.isEmpty(teams)) {
              throw new Error('Ошибка: нет оффиса у юзера');
            }
            const { is_active_in_team: isActive, id: teamId } = helper.getOneArray(teams) || {};
            await this.$store.dispatch(`team/${TEAM_ACTIVE_USER_OR_DEACTIVATE}`, {
              operatorId: userId,
              teamId
            });
            await this.$_ajax();
            this.$Notify({
              title: 'User',
              message: `Successfully ${!isActive ? 'active' : 'deactivate'}`,
              type: 'success'
            });
          } catch (e) {
            throw Error('Ошибка блокировки');
          }
          break;
      }
    }
  }
};
</script>
